<template>
  <div class="home">
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data(){
    return{
      slide: 'style',
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.'

    }
  },
  mounted(){

  },
  methods:{

  },
  computed:{
    maxHeight(){
      return this.$q.screen.height
    }
  }
}
</script>
<style lang="scss" scoped>
.home-img{
  width: 350px;
  object-fit: cover;
  border-radius: 7%;
}
</style>
